<header class="bg-white shadow">
  <div class="container mx-auto px-4 py-4 flex items-center justify-between">
    <div class="text-xl font-bold">Logo</div>
    <div class="flex items-center space-x-4">
      <button class="p-2 rounded-full bg-[#F5F7FA]">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1457 12.5001C16.1457 14.5136 14.5134 16.1459 12.4998 16.1459C10.4863 16.1459 8.854 14.5136 8.854 12.5001C8.854 10.4865 10.4863 8.85425 12.4998 8.85425C14.5134 8.85425 16.1457 10.4865 16.1457 12.5001Z"
            stroke="#718EBF"
            stroke-width="1.5625"
          />
          <path
            d="M21.657 9.53334C22.4969 10.981 22.9168 11.7048 22.9168 12.5C22.9168 13.2952 22.4969 14.019 21.657 15.4667L19.6532 18.9206C18.8166 20.3625 18.3984 21.0835 17.7105 21.4793C17.0227 21.875 16.1879 21.875 14.5182 21.875H10.4821C8.81248 21.875 7.97766 21.875 7.28982 21.4793C6.60198 21.0835 6.18369 20.3625 5.34715 18.9206L3.34331 15.4667C2.50343 14.019 2.0835 13.2952 2.0835 12.5C2.0835 11.7048 2.50343 10.981 3.34331 9.53333L5.34715 6.07939C6.18369 4.63746 6.60198 3.9165 7.28982 3.52075C7.97766 3.125 8.81248 3.125 10.4821 3.125H14.5182C16.1879 3.125 17.0227 3.125 17.7105 3.52075C18.3984 3.9165 18.8166 4.63747 19.6532 6.0794L21.657 9.53334Z"
            stroke="#718EBF"
            stroke-width="1.5625"
          />
        </svg>
      </button>
      <button class="p-2 rounded-full bg-[#F5F7FA]">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.63517 15.3849C2.41366 16.837 3.40401 17.8449 4.61656 18.3472C9.26527 20.273 15.7344 20.273 20.3831 18.3472C21.5957 17.8449 22.586 16.837 22.3645 15.3849C22.2284 14.4925 21.5553 13.7494 21.0565 13.0238C20.4033 12.0617 20.3384 11.0122 20.3383 9.89575C20.3383 5.58102 16.8289 2.08325 12.4998 2.08325C8.17081 2.08325 4.66143 5.58102 4.66143 9.89575C4.66133 11.0122 4.59642 12.0617 3.94318 13.0238C3.44446 13.7494 2.77131 14.4925 2.63517 15.3849Z"
            stroke="#FE5C73"
            stroke-width="1.5625"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.3335 19.7917C8.81109 21.5888 10.4955 22.9167 12.5002 22.9167C14.5049 22.9167 16.1892 21.5888 16.6668 19.7917"
            stroke="#FE5C73"
            stroke-width="1.5625"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div
        class="flex items-center space-x-2 bg-white p-3 rounded-lg justify-between gap-8"
      >
        <div class="flex items-center gap-2">
          <img
            src="https://media.licdn.com/dms/image/v2/D4E03AQFrId6o-6XZYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727603098835?e=2147483647&v=beta&t=XzUdcR6gaPIE_ftEZ5y4Zejiy1yEJ1KARaOoqSIJrGM"
            alt="Profile"
            class="w-10 h-10 aspect-square rounded-full"
          />
          <div>
            <div class="font-semibold">Administrateur</div>
            <div class="text-sm text-gray-500">bonkaristidegmail.com</div>
          </div>
        </div>

        <div
          class="flex items-center justify-center cursor-pointer"
          (click)="confirm()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 6.5C4.15875 8.14796 3 10.3344 3 12.9999C3 17.9705 7.02944 21.9999 12 21.9999C16.9706 21.9999 21 17.9705 21 12.9999C21 10.3344 19.8412 8.14796 18 6.5"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 2V11M12 2C11.2998 2 9.99153 3.9943 9.5 4.5M12 2C12.7002 2 14.0085 3.9943 14.5 4.5"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <weni-menu-list></weni-menu-list>
</header>
