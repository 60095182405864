<nav class="!bg-none shadow-sm">
  <!-- Mobile & Tablet View -->
  <div class="lg:hidden">
    <div class="flex items-center justify-between p-4">
      <span class="font-semibold">Menu</span>
      <button
        (click)="toggleMenu()"
        class="p-2 rounded-md hover:bg-gray-100 focus:outline-none"
      >
        <ng-container *ngIf="!isOpen">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </ng-container>
        <ng-container *ngIf="isOpen">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            /></svg
        ></ng-container>
      </button>
    </div>

    <!-- Mobile Menu -->
    <div
      class="absolute w-full bg-white shadow-lg index"
      [class.block]="isOpen"
      [class.hidden]="!isOpen"
    >
      <div class="py-2">
        <ng-container *ngFor="let item of menuItems">
          <!-- Regular menu items -->
          <a
          *ngIf="!item.subItems"
          [routerLink]="item.link"
          routerLinkActive="text-primary"
          class="block px-4 py-2 text-gray-900 hover:bg-gray-50"
          (click)="closeMenu()"
          >
            {{ item.label }}
          </a>

          <!-- Items with submenu -->
          <div *ngIf="item.subItems" class="relative">
            <button
              (click)="toggleSubMenu(item)"
              class="w-full px-4 py-2 text-left text-gray-900 hover:bg-gray-50 flex justify-between items-center"
            >
              {{ item.label }}
              <svg
                class="w-5 h-5"
                [class.rotate-180]="item.isOpen"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <div *ngIf="item.subItems" class="bg-gray-50">
              <a
                *ngFor="let subItem of item.subItems"
                (click)="$event.preventDefault(); handleMenuItemClick(subItem)"
                class="block pl-8 pr-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              >
                {{ subItem.label }}
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Desktop View -->
  <div class="hidden lg:block w-full">
    <div class="container mx-auto px-4">
      <div class="relative flex items-center justify-start">
        <!-- Bouton de défilement gauche -->
        <button
          *ngIf="canScrollLeft"
          (click)="scroll('left')"
          class="absolute left-0 z-10 -mt-3 aspect-square p-2 rounded-full bg-white shadow-md flex items-center justify-center hover:bg-gray-100 transition-all"
          aria-label="Défiler à gauche"
        >
          <i nz-icon nzType="left" nzTheme="outline"></i>
        </button>
        <ul
          #navList
          class="flex flex-nowrap overflow-x-scroll py-4 px-8 scrollbar-hide scroll-smooth"
          (scroll)="checkScroll()"
        >
          <li
            *ngFor="let item of menuItems"
            class="flex-none mx-1 first:ml-0 last:mr-0 relative group"
          >
            <ng-container *ngIf="!item.subItems">
              <weni-menu-item [item]="item"></weni-menu-item>
            </ng-container>

            <div *ngIf="item.subItems" class="inline-block -mt-2 relative">
              <weni-sub-menu-item
                [isActive]="activeMenuId === item.id"
                (menuToggled)="handleMenuToggle(item.id)"
                [item]="item"
                [activeMenuId]="activeMenuId"
                (itemClicked)="handleMenuItemClick($event)"
              ></weni-sub-menu-item>
            </div>
          </li>
        </ul>

        <!-- Bouton de défilement droit -->
        <button
          *ngIf="canScrollRight"
          (click)="scroll('right')"
          class="absolute right-0 z-10 -mt-3 aspect-square p-2 rounded-full bg-white flex items-center justify-center shadow-md hover:bg-gray-100 transition-all"
          aria-label="Défiler à droite"
        >
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </button>
      </div>
    </div>
  </div>
</nav>
