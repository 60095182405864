import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _service: NzNotificationService) {}

  show(title: string, message: string, mode: string = 'success') {
    this._service.success(title, message);
  }
}
