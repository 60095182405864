import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SubMenuItemComponent } from './components/sub-menu-item/sub-menu-item.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { getModalWidth } from 'src/app/core/constantes';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StateIndexComponent } from 'src/app/pages/state/state-index/state-index.component';

interface MenuItem {
  label: string;
  link?: string[];
  id: string;
  subItems?: {
    id: string;
    label: string;
    link?: string[];
    action?: () => void;
    openModal?: boolean;
  }[];
  openModal?: boolean;
  isOpen?: boolean;
}

@Component({
  selector: 'weni-menu-list',
  standalone: true,
  imports: [
    CommonModule,
    SubMenuItemComponent,
    MenuItemComponent,
    NzDropDownModule,
    NzIconModule,
    RouterModule,
  ],
  templateUrl: './menu-list.component.html',
})
export class MenuListComponent {
  @ViewChild('navList') navList!: ElementRef;
  isOpen = false;
  canScrollLeft = false;
  canScrollRight = false;
  activeMenuId: string | null = null;

  constructor(
    private router: Router,
    private modalService: NzModalService
  ) {
    document.addEventListener('click', (event: Event) => {
      const target = event.target as HTMLElement;
      if (!target.closest('nav')) {
        this.closeMenu();
      }
    });
  }

  openStateModal(): void {
    console.log('Tentative d\'ouverture du modal'); // Debug log
    try {
      const modalRef = this.modalService.create({
        nzContent: StateIndexComponent,
        nzFooter: null,
        nzWidth: '80%',
        nzClassName: 'custom-modal-class',
        nzCentered: true,
        nzClosable: true,
        nzMaskClosable: true
      });

      modalRef.afterOpen.subscribe(() => {
        console.log('Modal ouvert avec succès'); // Debug log
      });

      modalRef.afterClose.subscribe((result) => {
        console.log('Modal fermé', result); // Debug log
      });

    } catch (error) {
      console.error('Erreur lors de l\'ouverture du modal:', error); // Debug log
    }
  }


  handleMenuItemClick(item: any): void {
    console.log('handleMenuItemClick appelé avec:', item); // Debug log
    if (item.action) {
      item.action();
    } else if (item.link) {
      this.router.navigate(item.link);
    }
    this.closeMenu();
  }

  reloadData(): void {
    // Implémenter la logique de rechargement des données
    console.log('Reloading data...');
  }
  // navigateToPackages(): void {
  //   this.router.navigate(['/panel/shipments/all']);
  //   this.onCreate.open(RapportModalComponent, { centered: true });
  // }
  
  menuItems: MenuItem[] = [
    {
      id: 'dashboard',
      label: 'Tableau de bord',
      link: ['/panel/dashboard/home'],
    },
    {
      id: 'payment-requests',
      label: 'Demande de paiements',
      link: ['/panel/payment-request/all'],
    },
    {
      id: 'admins',
      label: 'Administrateurs',
      link: ['/panel/admins/all'],
    },
    {
      id: 'orders',
      label: 'Commandes',
      subItems: [
        {
          id: 'orders-customers',
          label: 'Clients',
          link: ['/panel/shipping/customer-orders/list'],
        },
        {
          id: 'orders-suppliers',
          label: 'Fournisseurs',
          link: ['/panel/shipping/supplier-orders/list'],
        },
        {
          id: 'orders-packages',
          label: 'Colis',
          link: ['/panel/shipments/all'],
        },
        {
          id: 'localisation-delivery',
          label: 'Localisation livreur',
          link: ['/panel/shipments/driver-location'],
        },
        {
          id: 'rapport',
          label: 'Rapport',
          link: ['/panel/shipping/report/list'],
        },
        {
          id: 'rapport-day',
          label: 'Rapport journalier',
          link: ['/panel/states/index'],
          action: () => {
            console.log('Clic sur Rapport journalier'); // Debug log
            this.openStateModal();}
        },
       
      ],
    },
    {
      id: 'account',
      label: 'Gestion de compte',
      subItems: [
        {
          id: 'boutique',
          label: 'Boutiques ',
          link: ['/panel/account/shop/home'],
        },
        {
          id: 'fournisseurs',
          label: 'Fournisseurs',
          link: ['/panel/account/shop-accounts/home'],
        },
        {
          id: 'commerciaux',
          label: 'Commerciaux',
          link: ['/panel/account/sales/home'],
        },
        {
          id: 'logisticiens',
          label: 'Logisticiens',
          link: ['/panel/account/logisticians/home'],
        },
        {
          id: 'livreurs',
          label: 'Livreurs',
          link: ['/panel/account/drivers/home'],
        },
        {
          id: 'user-mobiles',
          label: 'Utilisateurs mobiles',
          link: ['/panel/account/customers/list'],
        },
      ],
    },
    // {
    //   id: 'vehicles',
    //   label: 'Gestion des engins',
    //   subItems: [
    //     {
    //       id: 'vehicles-list',
    //       label: 'Liste des engins',
    //       link: ['/panel/equipments/engines/all'],
    //     },
    //     {
    //       id: 'historiques',
    //       label: 'historiques',
    //       link: ['/panel/equipments/histories'],
    //     },
    //     {
    //       id: 'sinistres',
    //       label: 'Sinistres',
    //       link: ['/panel/equipments/claims'],
    //     },
    //   ],
    // },
    {
      id: 'security',
      label: 'Sécurité',
      subItems: [
        {
          id: 'security-permissions',
          label: 'Permissions',
          link: ['/panel/security/permissions'],
        },
        {
          id: 'profil-management',
          label: 'Profils',
          link: ['/panel/security/profiles/all'],
        },
      ],
    },
    {
      id: 'reference',
      label: 'Référentiel',
      subItems: [
        {
          id: 'area',
          label: 'Zones',
          link: ['/panel/referential/area/list'],
        },
        {
          id: 'relance-motif',
          label: 'Motifs de relances',
          link: ['/panel/referential/relaunch/list'],
        },
        
        {
          id: 'return-motif',
          label: 'Motifs de retours',
          link: ['/panel/referential/return/list'],
        },
        {
          id: 'marques',
          label: 'Marques',
          link: ['/panel/equipments/brands/all'],
        },
        {
          id: 'ville',
          label: 'Ville',
          link: ['/panel/referential/city/all'],
        },
        {
          id: 'pays',
          label: 'Pays',
          link: ['/panel/referential/countries/all'],
        },
      ],
    },
    // {
    //   id: 'faq',
    //   label: 'Faq',
    //   link: ['/faq'],
    // },
    {
      id: 'services',
      label: 'Services et tarifications',
      subItems: [
        {
          id: 'services-livraison',
          label: 'Services livraison',
          link: ['/panel/referential/delivery-services/list'],
        },
        {
          id: 'Tarification',
          label: 'Tarifications Zone',
          link: ['/panel/pricing/list'],
        },
      ],
    },
  ];



  @HostListener('window:resize')
  onResize() {
    this.checkScroll();
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }
  toggleSubMenu(item: MenuItem) {
    item.isOpen = !item.isOpen;
  }

  closeMenu(): void {
    this.isOpen = false;
  }

  scroll(direction: 'left' | 'right') {
    const scrollAmount = 200;
    if (this.navList) {
      const element = this.navList.nativeElement;
      if (direction === 'left') {
        element.scrollLeft -= scrollAmount;
      } else {
        element.scrollLeft += scrollAmount;
      }
      this.checkScroll();
    }
  }

  checkScroll() {
    if (this.navList) {
      const element = this.navList.nativeElement;
      this.canScrollLeft = element.scrollLeft > 0;
      this.canScrollRight =
        element.scrollLeft < element.scrollWidth - element.clientWidth;
    }
  }

  ngAfterViewInit() {
    this.checkScroll();
  }

  handleMenuToggle(menuId: string) {
    console.log(`Id du menu ${menuId}`);
    this.activeMenuId = this.activeMenuId === menuId ? null : menuId;
  }
}
