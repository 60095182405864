import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'weni-menu-item',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.less',
})
export class MenuItemComponent {
  @Input('item') item!: any;
}
