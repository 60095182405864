export function formatCustomStartDate(inputDate: string) {
  const date = new Date(inputDate);
  const formattedDate = `${date.toISOString().split('T')[0]}T00:00:00.000000`;
  return formattedDate;
}
export function formatCustomEndDate(inputDate: string) {
  const date = new Date(inputDate);
  const formattedDate = `${date.toISOString().split('T')[0]}T23:59:59.000000`;
  return formattedDate;
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
