import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  LOCALE_ID,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NZ_I18N, fr_FR } from 'ng-zorro-antd/i18n';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { appRoutes } from './app/app-routes';
import { ErrorInterceptor } from './app/core/helpers/error.interceptor';
import { JwtInterceptor } from './app/core/helpers/jwt.interceptor';
import { ngZorroConfig } from './app/core/ngZorroConfig';
import { provideRouter } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, FormsModule),
    BrowserAnimationsModule,
    provideRouter(appRoutes),
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CFA' },

    NzMessageService,
    NzNotificationService,
    NzModalService,
    NzDrawerService,
    NzImageService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideExperimentalZonelessChangeDetection(),
  ],
};
