import { Component } from '@angular/core';
import { MenuListComponent } from '../menu-list/menu-list.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { UtilsService } from 'src/app/core/services/utils.service';
@Component({
  selector: 'weni-app-header',
  standalone: true,
  imports: [MenuListComponent, NzPopconfirmModule],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.less',
})
export class AppHeaderComponent {
  constructor(private service: UtilsService) {}

  confirm(): void {
    this.service.LogOut();
  }
}
