import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  Input,
  input,
  signal,
  ViewChild,
} from '@angular/core';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'outline'
  | 'ghost'
  | 'danger'
  | 'customDark'
   | 'secondaryOutline'
  | 'blackOutline'
type ButtonSize = 'sm' | 'md' | 'lg';

@Component({
  selector: 'weni-primary-action-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './primary-action-button.component.html',
  styleUrl: './primary-action-button.component.less',
})
export class PrimaryActionButtonComponent {
  @ViewChild('buttonRef') buttonRef!: ElementRef;
  @ViewChild('rippleElement') rippleElement!: ElementRef;

  // Required inputs
  label = input.required<string>();

  // Optional inputs with defaults
  variant = input<ButtonVariant>('primary');
  size = input<ButtonSize>('md');
  type = input<'button' | 'submit' | 'reset'>('button');
  isLoading = input<boolean>(false);
  disabled = input<boolean>(false);
  fullWidth = input<boolean>(false);
  leftIcon = input<string>('');
  rightIcon = input<string>('');
  customClasses = input<string>('');

  private isPressed = signal(false);
  private rippleTimeout: any;

  constructor(private cdr: ChangeDetectorRef) {
    effect(() => {
      this.cdr.markForCheck();
    });
  }

  private readonly baseClasses = `
    relative
    inline-flex
    items-center
    justify-center
    transition-all
    duration-200
    rounded-md
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    disabled:cursor-not-allowed
    disabled:opacity-60
  `;

  private readonly variantClasses = {
    primary: `
    bg-primary
    hover:bg-primary-hover
    active:bg-primary-active
    text-white
    focus:ring-primary/50
  `,
    primaryOutline: `
    border-2
    border-primary
    text-primary
    hover:bg-primary
    hover:text-white
    focus:ring-primary/50
  `,
    secondary: `
    bg-secondary
    hover:bg-secondary-hover
    active:bg-secondary-active
    text-white
    focus:ring-secondary/50
  `,
    secondaryOutline: `
    border-2
    border-secondary
    text-secondary
    hover:bg-secondary
    hover:text-white
    focus:ring-secondary/50
  `,
    success: `
    bg-success
    hover:bg-success-hover
    active:bg-success-active
    text-white
    focus:ring-success/50
  `,
    successOutline: `
    border-2
    border-success
    text-success
    hover:bg-success
    hover:text-white
    focus:ring-success/50
  `,
    outline: `
    border-2
    border-primary
    text-primary
    hover:bg-primary
    hover:text-white
    focus:ring-primary/50
  `,
    ghost: `
    text-primary
    hover:bg-primary/10
    focus:ring-primary/50
  `,
    danger: `
    bg-red-600
    hover:bg-red-700
    active:bg-red-800
    text-white
    focus:ring-red-500/50
  `,
    customDark: `
    bg-customDark
    hover:bg-customDark-hover
    active:bg-customDark-active
    text-white
    focus:ring-customDark/50
  `,
    blackOutline: `
    border
    border-black
    text-black
    hover:bg-black
    hover:text-white
    focus:ring-black/50
  `,
  };

  private readonly sizeClasses = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
  };

  protected readonly computedClasses = computed(() => {
    const classes = [
      this.baseClasses,
      this.variantClasses[this.variant()],
      this.sizeClasses[this.size()],
      this.fullWidth() ? 'w-full' : '',
      this.isPressed() ? 'scale-[0.98] ring-2' : '',
      this.customClasses(),
    ];

    return classes.join(' ').trim();
  });

  protected onMouseDown(event: MouseEvent): void {
    if (!this.disabled() && !this.isLoading()) {
      this.isPressed.set(true);
      this.startRippleEffect(event);
      this.cdr.markForCheck();
    }
  }

  protected onMouseUp(): void {
    if (!this.disabled() && !this.isLoading()) {
      this.isPressed.set(false);
      this.stopRippleEffect();
      this.cdr.markForCheck();
    }
  }

  protected onMouseLeave(): void {
    if (this.isPressed()) {
      this.isPressed.set(false);
      this.stopRippleEffect();
      this.cdr.markForCheck();
    }
  }

  private startRippleEffect(event: MouseEvent): void {
    const button = this.buttonRef.nativeElement;
    const ripple = this.rippleElement.nativeElement;
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    ripple.style.top = `${event.clientY - button.offsetTop - radius}px`;
    ripple.classList.add('active');
  }

  private stopRippleEffect(): void {
    const ripple = this.rippleElement.nativeElement;
    ripple.classList.remove('active');

    this.rippleTimeout = setTimeout(() => {
      ripple.style.width = ripple.style.height = '10px';
      ripple.style.left = ripple.style.top = '50%';
    }, 600);
  }

  ngOnDestroy(): void {
    if (this.rippleTimeout) {
      clearTimeout(this.rippleTimeout);
    }
  }
}
